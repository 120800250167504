<script lang="ts" setup>
  import RouletteSlider from '~/components/sliders/roulette/RouletteSlider.vue';
  import { getNoveltyMovies } from '~/services/modules/movies.service';

  const { data, isFetching, suspense } = getNoveltyMovies({ params: { novelty: 0, category: 'serial' } });

  onServerPrefetch(async () => await suspense());
</script>

<template>
  <v-wrapper
    section
    class="series full-width"
    title="Сериалы"
    remove-padding
    :link="{ name: 'Смотреть все', route: { name: 'novelty-series' } }"
  >
    <roulette-slider
      appendClass="popular__slider content-right"
      :items="data ?? []"
      :is-fetching="isFetching"
      skeleton
    />
  </v-wrapper>
</template>

<style lang="scss" scoped>
  .series {
    padding: 56px 0 0;
    @media (max-width: $retina) {
      padding: 24px 0;
    }
  }
</style>
